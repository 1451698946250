<template>
	<validation-provider
		#default="{ errors }"
		:name="$props.label"
		:rules="rules"
		:mode="validationMode"
		:custom-messages="customMessages"
		tag="div"
		class="d-flex flex-row cassie-horizontal-md"
	>
		<v-file-input
			v-bind="$props"
			ref="fileInput"
			:accept="accept"
			:rules="[]"
			:error-messages="errors.length ? [errors[0]] : []"
			prepend-icon="mdi-upload"
			class="qa-fileinput"
			outlined
			dense
			hide-details
			@change="selectFile"
		>
			<v-tooltip
				v-if="tooltipText"
				slot="append"
				right
			>
				<template #activator="{ on }">
					<v-icon
						light
						v-on="on"
					>
						mdi-information
					</v-icon>
				</template>
				<span>
					{{ tooltipText }}
				</span>
			</v-tooltip>
		</v-file-input>
		<PrimaryActionButton
			v-if="!selectFileOnly"
			:disabled="!file"
			@click="onUploadClick"
		>
			Upload
		</PrimaryActionButton>
	</validation-provider>
</template>

<script>
import { VFileInput } from 'vuetify/lib'
import PrimaryActionButton from './primary-action-button.vue'
import FieldValidationMixin from '../validation/field-validation-mixin.js'
export default {
	name: 'file-input',
	components: { PrimaryActionButton },
	extends: VFileInput,
	mixins: [FieldValidationMixin],
	props: {
		accept: {
			type: String
		},
		selectFileOnly: {
			type: Boolean,
			default: false
		},
		tooltipText: {
			type: String
		}
	},
	data () {
		return {
			file: null
		}
	},
	methods: {
		onUploadClick () {
			this.$emit('uploadFile', this.file)
			this.file = null
		},
		selectFile (file) {
			this.$emit('selectFile', file)
			this.file = file
		}
	}
}
</script>
